import style from "@/app/styles.module.css";
import { useState, useRef, createElement, useEffect } from "react";
import { useRouter } from 'next/router';
import { Fragment } from "react";
import Link from "next/link";
import dynamic from "next/dynamic";

 
import {Helper} from './helper';
import Head from "next/head";
import Highlight from 'react-highlight'
import Image from "next/image"; 

import AdCompaignBox from "./ad_campaign";
 
import hljs from 'highlight.js';

const CodeHighlighter = () => {
    useEffect(() => {
        const codeBlocks = document.querySelectorAll('pre code');
        codeBlocks.forEach((block) => {
            hljs.highlightElement(block);
        });
    }, []);

    return null; // No UI rendered by this component
};

var HandleCodeBlock = ({code_value}) => {

  //// console.log(code_value);
   // Match the <code> tag with the class attribute
   const classMatch = code_value.match(/<code\s+class="([^"]+)"/);
   const classValue = classMatch ? classMatch[1] : null;
 
   // Match the content inside the <code> element
   const contentMatch = code_value.match(/<code\s+class="[^"]+">([\s\S]*?)<\/code><\/pre>/);
   const codeValue = contentMatch ? contentMatch[1].trim() : null;
  // //// console.log(classValue, codeValue);

 
   return  <Highlight className={classValue}>
   {codeValue}
 </Highlight>;

    

   /*
  return html
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;'); */
}



var FaqHandleCodeBlock = ({code_value}) => {
 
   // Match the <code> tag with the class attribute
   const classMatch = code_value.match(/<code\s+class="([^"]+)"/);
   const classValue = classMatch ? classMatch[1] : null;
 
   // Match the content inside the <code> element
   const contentMatch = code_value.match(/<code\s+class="[^"]+">([\s\S]*?)<\/code><\/pre>/);
   const codeValue = contentMatch ? contentMatch[1].trim() : null;
  // //// console.log(classValue, codeValue);

 
   return  <Highlight className={classValue}>
   {codeValue}
 </Highlight>;

    

   /*
  return html
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;'); */
}
import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  GabIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from 'react-share';

function ServerOffline() {
  return (
    <>
      <Head>
        <title>500: Server Offline</title>
      </Head>
      <div className={style['error-500']}>
        <h1>500</h1>
        <h2>Server Offline</h2>
        <p>When the server goes offline, it disrupts access to the website. This issue can stem from various causes, including maintenance, technical failures, or unexpected traffic spikes.</p>
      </div>
    </>
  );
}

function SearchComponent ({searchType}) {
    
    var [query, setQuery] = useState('');
    var [is_pressed, setIsPressed] = useState(false);
 
    const router = useRouter();

    var sendRequest = (e) => {
        e.preventDefault(); 
        setIsPressed(true);
        setTimeout(() => {
          setIsPressed(false);
          router.push(`/search?q=${query}`)
        }, 3000)
        
    }

    // seach components 
    var render = (
      <form className={style["search-form"]} style={{marginTop: '25px'}}>
          <input onChange={e => setQuery(e.target.value)} value={query} type="text" placeholder="What are you looking for?" />
          <button
            aria-label="Search on site"
            onClick={sendRequest}
            className={`${style.btn} ${style['third-btn']} ${style['radius-5']} ${style['custom-header-btn']}`}
          >
            {is_pressed ? <span className={`${style.loader}`}></span> : 'Search'}
          </button>

      </form>
    );

    // sidebar seach components 
    if( searchType == 'sidebar' ) {
      render = (
        <form className={`${style['form-group']} ${style['form-1']}`} action="/" method="get">
          <input 
            onChange={e => setQuery(e.target.value)} 
            value={query} 
            type="text" 
            placeholder="Search in our tutorials" 
          />
          <button 
            aria-label="Search on site" 
            onClick={sendRequest} 
            type="submit"
          >
            {is_pressed ? (
              <span className={`${style.loader} ${style['black-loader']}`}></span>
            ) : (
              <span className={`${style.flexbox}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle cx="11" cy="11" r="7" className={`${style['stroke-color']}`} stroke="#33363F" strokeWidth="2" />
                  <path 
                    d="M20 20L17 17" 
                    className={`${style['stroke-color']}`} 
                    stroke="#33363F" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                  />
                </svg>
              </span>
            )}
          </button>
        </form>

      );
    }

    return render;

}

function AdCompaignBoxOld({ position, data, classes }) {

  const adRef = useRef(null);

  if (!data) {
    return null;
  }

  const index = data.findIndex(x => x.position === position);
  if (index === -1) {
    return null;
  }

  useEffect(() => {
    if (!adRef.current) return;

    const box = data[index].code;
    adRef.current.innerHTML = box;
    
    const scripts = adRef.current.querySelectorAll('script');
    scripts.forEach(script => {
      const newScript = document.createElement('script');
      newScript.textContent = script.textContent;
      Array.from(script.attributes).forEach(attr => {
        newScript.setAttribute(attr.name, attr.value);
      });
      script.parentNode.replaceChild(newScript, script);
    });
  }, [data, index]);

  const combinedClasses = classes ? `ad-box ${classes}` : 'ad-box';

  return <div className={combinedClasses} ref={adRef}></div>;
};





var GenerateTutorialContent_tab = ({ data, upcoming, built_url, ad_camp, adsReady }) => {
  // Split the data by the delimiter "|"
  const parts = data.split('|').map(part => part.trim());

  // Process the parts to create the appropriate elements
  return (
    <>
      {parts.map((part, index) => {
        // YouTube shortcode
        if (part.startsWith('[youtube')) {
          const src = part.match(/src="([^"]+)"/)[1];
          return (
            <Fragment key={index}>
            <div className={`${style['mt-25']}`}>
              <LazyLoadYouTube cls={`${style['ifram-tut-youtube']}`} url={src} />
            </div>
          
            {adsReady ? (
              <AdCompaignBox 
                settings={upcoming.settings} 
                data={ad_camp} 
                position={'after_youtube_video_content_1'} 
              />
            ) : (
              ""
            )}
          </Fragment>          
          );
        }
        // Headline shortcodes from h1 to h6
        else if (part.match(/^\[h[1-6]/)) {
          const tag = part.match(/^\[h([1-6])/)[1];
          const content = part.replace(/^\[h[1-6]\]/, '').trim();
          const TagName = `h${tag}`;
          return <TagName key={index} className={`${style['tutorial-subheadline']}`}>{Helper.decodeHtmlEntities(content)}</TagName>;
        }
        // Chapters and posts shortcode
        else if (part.startsWith('[chapters-posts]')) {
          if(upcoming != undefined )
            return <TutorialLinks key={index} ad_camp={ad_camp} built_url={built_url} upcoming={upcoming} adsReady={adsReady} />;
        } 
        // Default case: plain paragraph
        else {
          return (
            <p key={index} className={`${style['tutorial-description']}`}>
            {part}
          </p>          
          );
        }
      })}

      { adsReady?<AdCompaignBox settings={upcoming.settings} data={ad_camp} position={'after_tutorial_description_1'}/>: "" }
    </>
  );
}

var FeedBackBlock = ({data_id, data_title, feeadback_title }) => {

  feeadback_title = feeadback_title == undefined ? 'Did you find this tutorial useful?': feeadback_title;
  const textareaRef = useRef(null);

  var [feedback, feedback_change] = useState({
    thumb: null, 
    comment: '', 
    data_id: data_id,
    data_title: data_title
  });

  

  const [isDisabled, setIsDisabled] = useState(false);
  var [data, data_change] = useState({
    is_pressed: false, 
    message: "",
    type: "", // error, success 
    exposed: 'none-display',
    press_type: 'comment',
    hide_form: 'hide'
  })

  // functions  
  var changed_data_callback = (obj) => {
      var old_objec = {...data};
      var __keys = Object.keys(obj);
      __keys.map(x => {
          old_objec[x] = obj[x]
      }); 
      data_change(old_objec);
  } 

  var changed_feedback_callback = (obj) => {
      var old_objec = {...feedback};
      var __keys = Object.keys(obj);
      __keys.map(x => {
          old_objec[x] = obj[x]
      }); 
       
      feedback_change(old_objec);
  } 

  var submit_feedback = async (e, presstype ) => {
  
      e.preventDefault();  
      
      changed_data_callback({
        press_type: presstype,
        is_pressed: true
      })
      
      // //// console.log(feedback);
      var res = await Helper.sendRequest({
        api: "comments/create-update",
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(feedback), 
        method: "post"
      }); 

      var response =  await res.json(); 
       
      if( presstype == 'comment' ) {
        
        if( response.is_error ) {
          changed_data_callback({
            message: 'Unable to send your feedback due to an error.',
            type: 'error',
            exposed: '', 
            is_pressed: false
          })

          return;
        }
        
        setIsDisabled(true);
        changed_data_callback({
          message: 'Thank you for your feedback! We will address the issue promptly.',
          type: 'success',
          exposed: '', 
          is_pressed: false
        })

        return;
      }

      if( response.is_error ) {
        changed_data_callback({
          press_type: presstype,
          is_pressed: false
        })
        return; 
      }

      setIsDisabled(true); 
      changed_data_callback({ 
        is_pressed: false,
        hide_form: 'hide'
      })
      
  }

  

  var thumbUpHandler = ( e, press_type ) => {
   

      feedback.thumb= true;

      ////// console.log(feedback);
      setTimeout(() => submit_feedback(e, press_type), 100);
      e.preventDefault();

  }

  var thumbDownHandler = ( e, press_type ) => {
      
      
      // it only show input text
      changed_feedback_callback({thumb: false }) 
      changed_data_callback({hide_form: ''});

      setTimeout(() => {
        // setIsDisabled(true);
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      }, 50)

      // submit_feedback(e, press_type)
      e.preventDefault();


  }   


  var ThumbUp = () => (
      <svg height="25" width="25" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M320 1344q0-26-19-45t-45-19q-27 0-45.5 19t-18.5 45q0 27 18.5 45.5t45.5 18.5q26 0 45-18.5t19-45.5zm160-512v640q0 26-19 45t-45 19h-288q-26 0-45-19t-19-45v-640q0-26 19-45t45-19h288q26 0 45 19t19 45zm1184 0q0 86-55 149 15 44 15 76 3 76-43 137 17 56 0 117-15 57-54 94 9 112-49 181-64 76-197 78h-129q-66 0-144-15.5t-121.5-29-120.5-39.5q-123-43-158-44-26-1-45-19.5t-19-44.5v-641q0-25 18-43.5t43-20.5q24-2 76-59t101-121q68-87 101-120 18-18 31-48t17.5-48.5 13.5-60.5q7-39 12.5-61t19.5-52 34-50q19-19 45-19 46 0 82.5 10.5t60 26 40 40.5 24 45 12 50 5 45 .5 39q0 38-9.5 76t-19 60-27.5 56q-3 6-10 18t-11 22-8 24h277q78 0 135 57t57 135z"/></svg>
  )
  
  var ThumbDown = () => (
      <svg height="25" width="25" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M320 576q0 26-19 45t-45 19q-27 0-45.5-19t-18.5-45q0-27 18.5-45.5t45.5-18.5q26 0 45 18.5t19 45.5zm160 512v-640q0-26-19-45t-45-19h-288q-26 0-45 19t-19 45v640q0 26 19 45t45 19h288q26 0 45-19t19-45zm1129-149q55 61 55 149-1 78-57.5 135t-134.5 57h-277q4 14 8 24t11 22 10 18q18 37 27 57t19 58.5 10 76.5q0 24-.5 39t-5 45-12 50-24 45-40 40.5-60 26-82.5 10.5q-26 0-45-19-20-20-34-50t-19.5-52-12.5-61q-9-42-13.5-60.5t-17.5-48.5-31-48q-33-33-101-120-49-64-101-121t-76-59q-25-2-43-20.5t-18-43.5v-641q0-26 19-44.5t45-19.5q35-1 158-44 77-26 120.5-39.5t121.5-29 144-15.5h129q133 2 197 78 58 69 49 181 39 37 54 94 17 61 0 117 46 61 43 137 0 32-15 76z"/></svg>
  )
  
  return (
    <div className={`${style['feedback-block']} ${style['max-1050']} ${style['update-sider']}`}>
    <div className={`${style.flexbox} ${style['direction-row']} ${style['items-center']} ${style['space-between']} ${style['flex-wrap']}`}>
      <div className={`${style['ptb-10']}`}>
        <h3>{feeadback_title}</h3>
      </div>
      <div className={`${style.flexbox} ${style['direction-row']} ${style['gap-15']} ${style['ptb-10']}`}>
        <button 
          aria-label="Thumb Down" 
          disabled={isDisabled} 
          style={{ padding: 0 }} 
          className={`${style['x-thumb-down']} ${isDisabled ? style['disable-feedback'] : ''}`} 
          onClick={e => thumbDownHandler(e, "thumb-down")}
        >
          {data.is_pressed && data.press_type === 'thumb-down' ? (
            <span className={`${style.loader}`} style={{ borderBottomColor: '#f9756e' }}></span>
          ) : (
            <ThumbDown />
          )}
        </button>
  
        <button 
          aria-label="Thumb Up" 
          disabled={isDisabled} 
          style={{ padding: 0 }} 
          className={`${style['x-thumb-up']} ${isDisabled ? style['disable-feedback'] : ''}`} 
          onClick={e => thumbUpHandler(e, "thumb-up")}
        >
          {data.is_pressed && data.press_type === 'thumb-up' ? (
            <span className={`${style.loader}`} style={{ borderBottomColor: '#00bec4' }}></span>
          ) : (
            <ThumbUp />
          )}
        </button>
      </div>
    </div>
  
    <div className={`${style['feedback-form-block']} ${style[data.hide_form]}`}>
      <p className={`${style['mb-8']}`} style={{ marginBottom: '10px' }}>
        Your feedback helps us improve our tutorials.
      </p>
      <textarea
        ref={textareaRef}
        onChange={e => changed_feedback_callback({ comment: e.target.value })}
        value={feedback.comment}
        placeholder="write your feedback here!"
      ></textarea>
      <div className={`${style['feedback-response']} ${style[`msg-${data.type}`]} ${style[data.exposed]}`}>
        <p>{data.message}</p>
      </div>
      <button 
        disabled={isDisabled} 
        type="submit" 
        onClick={e => submit_feedback(e, "comment")} 
        className={`${style.btn} ${style['third-btn']} ${style['radius-5']} ${style['custom-header-btn']} ${style['auto-left']}`}
      >
        {data.is_pressed && data.press_type === 'comment' ? (
          <span className={`${style.loader}`}></span>
        ) : (
          'Submit'
        )}
      </button>
    </div>
  </div>  
  );

}

function SubscribeComponents ({is_footer, title, description, camp_data, settings, adsReady, isSmallBtn }) {

  var main_settings = settings; 
  
  var is_middle = false; 
  if( settings != undefined && settings.banner_image_url == "" ) {
    is_middle = true; 
  }

  var [email, setEmail] = useState('')
  var [result, setRestult] = useState({
      message: '',
      cls: '', // show
      type: '',  // error - success
      is_pressed: false
  });

  var response_results_callback = (obj) => {
      var old_objec = {...result};
      var __keys = Object.keys(obj);
      __keys.map(x => {
          old_objec[x] = obj[x]
      }); 
      setRestult(old_objec);
  } 
 
  var send_data = (e) => {
    
    e.preventDefault();

    response_results_callback({ 
      is_pressed: true
    }); 

    Helper.sendRequest({
      api: 'user/subscribe',
      data: {
        email: email
      },
      method: 'post'
    }).then( async row => {
      
      var res =  await row.json(); 
      var to_be_state = {};
      to_be_state.message= res.data;
      to_be_state.cls= 'show';
      to_be_state.is_pressed= false;

      if( res.is_error ) { 
        to_be_state.type= 'error';
      } else {
        to_be_state.type= 'success';
      }
      ////// console.log(to_be_state, res)
      response_results_callback(to_be_state);

      setTimeout(() => {
        response_results_callback({
          message: '',
          cls: '',
          type: ''
        });
      }, 3000)

    });


  }
  
  return (
    <>
      {
  is_footer ? (
    <h2 className={`${style.title}`}>{title}</h2>
  ) : (
    <h1 className={`${style['custom-headline']} ${style['section-head']}`} dangerouslySetInnerHTML={{ __html: title }} />
  )
}

{
  is_footer ? (
    <p className={`${style['font-16']} ${style['pb-15']}`}>{description}</p>
  ) : (
    <p>{description}</p>
  )
}

<div style={is_middle ? { margin: "0 auto" } : {}}>
  <div className={`${style['response-msg']} ${style[result.cls]} ${style[result.type]}`}>{result.message}</div>

  {is_footer ? (
    ""
  ) : (
    adsReady ? (
      <AdCompaignBox settings={main_settings} position="before_subscribe" data={camp_data} />
    ) : (
      ""
    )
  )}

  <form className={`${style['set-center']} ${style['form-group']} ${style['set-focus']}`} action="/" method="get">
    <input
      type="text"
      value={email}
      onChange={e => setEmail(e.target.value)}
      placeholder="example@email.com"
      className={`${isSmallBtn?' ' + style['subscribe-smallb-field']: ''}`}
    />
    <button className={`${style.btn} ${style['primary-btn']}${isSmallBtn?' ' + style['subscribe-smallb-btn']: ''}`} type="submit" onClick={send_data}>
      {result.is_pressed ? (
        <span className={`${style.loader}`}></span>
      ) : (
        "Subscribe"
      )}
    </button>
  </form>

  {is_footer ? (
    ""
  ) : (
    adsReady ? (
      <AdCompaignBox settings={main_settings} position="after_subscribe" data={camp_data} />
    ) : (
      ""
    )
  )}
</div>
    </>
  )
}




var CreateCaptcha = ({value}) => {




  var canvasRef = useRef(null);
  
  useEffect(() => {

    if ( canvasRef == null || !canvasRef.current) return;

    // generate width and height 
    canvasRef.current.width = 150;
    canvasRef.current.height = 70; 

    var context = canvasRef.current.getContext("2d");
    
    // background
    context.fillStyle = '#f0f0f0';
    context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    // calculate text position 
    var x =  (canvasRef.current.width / 2) - 50;
    var y = (canvasRef.current.height / 2) + 9;

    // Set text
    context.font = '22px Arial';
    context.fillStyle = '#000';

    context.fillText(value, x, y);

  }, [value]);

  return (
    <canvas ref={canvasRef}></canvas>
  );

}




var TutorialLinks = ({upcoming, built_url, ad_camp, adsReady}) => {
  
  var counter_ads = 0;
  var ads_every = upcoming.settings?.ads_between_navs_in_chapters ?upcoming.settings.ads_between_navs_in_chapters: 4;
  return (
    <div className={`${style.wrapper} ${style['chapter-elements']} ${style['max-1150']} ${style['offset-left']} ${style['offset-right']} ${style['mt-30']} ${style.flexbox} ${style['gap-20']} ${style['flex-wrap']} ${style['content-center']}`}>
    {upcoming.chapters.length ? (
      upcoming.chapters.map((chapter, k) => {
        // Counter for ads
        if (k % ads_every === 0) {
          counter_ads++;
        }
  
        return (
          <Fragment key={chapter._id}>
            {(k % ads_every === 0) &&
              (adsReady ? (
                <AdCompaignBox 
                  settings={upcoming.settings} 
                  data={ad_camp} 
                  position={`between_row_ad_${counter_ads}`} 
                />
              ) : (
                ""
              ))}
            <TutorialsList 
              built_url={built_url} 
              data={chapter.posts} 
              chapter_title={chapter.chapter_title} 
              index={k} 
            />
          </Fragment>
        );
      })
    ) : upcoming.posts.length ? (
      Helper.chunkArray(upcoming.posts, 3).map((posts, k) => {
        // Counter for ads
        if (k % ads_every === 0) {
          counter_ads++;
        }
  
        return (
          <Fragment key={k}>
            {(k % ads_every === 0) &&
              (adsReady ? (
                <AdCompaignBox 
                  settings={upcoming.settings} 
                  data={ad_camp} 
                  position={`between_row_ad_${counter_ads}`} 
                />
              ) : (
                ""
              ))}
            <TutorialsList 
              built_url={built_url} 
              data={posts} 
              index={k} 
            />
          </Fragment>
        );
      })
    ) : (
      ""
    )}
  </div>  
  );
}


















var GenerateTutorialContent_2 = ({ data, upcoming, built_url, ad_camp, adsReady }) => {

  // Split the data by the delimiter "|"
  const parts = data.split('|').map(part => part.trim());

  // Process the parts to create the appropriate elements
  return (
    <>
      {parts.map((part, index) => {
        // YouTube shortcode
        if (part.startsWith('[youtube')) {
          const src = part.match(/src="([^"]+)"/)[1];
          return (
            <Fragment key={index}>
              <div className={`${style['mt-25']}`}>
                <LazyLoadYouTube cls={`${style['ifram-tut-youtube']}`} url={src} />
              </div>
              {adsReady ? (
                <AdCompaignBox 
                  settings={upcoming.settings} 
                  data={ad_camp} 
                  position={'after_youtube_video_content_2'} 
                />
              ) : (
                ""
              )}
            </Fragment>

          );
        }
        // Headline shortcodes from h1 to h6
        else if (part.match(/^\[h[1-6]/)) {
          const tag = part.match(/^\[h([1-6])/)[1];
          const content = part.replace(/^\[h[1-6]\]/, '').trim();
          const TagName = `h${tag}`;
          return <TagName key={index} className={`${style['tutorial-subheadline']}`}>{Helper.decodeHtmlEntities(content)}</TagName>;
        }
        // Chapters and posts shortcode
        else if (part.startsWith('[chapters-posts]')) {
          if(upcoming != undefined )
            return <TutorialLinks adsReady={adsReady} ad_camp={ad_camp} key={index} built_url={built_url} upcoming={upcoming} />;
        } 
        // Default case: plain paragraph
        else {
          return (
            <p key={index} className={`${style['tutorial-description']}`}>
              {part}
            </p>
          );
        }
      })} 
      
      {adsReady ? (
        <AdCompaignBox 
          settings={upcoming.settings} 
          classes={`${style.wrapper} ${style['chapter-elements']} ${style['max-1150']} ${style['offset-left']} ${style['offset-right']} ${style['mt-30']} ${style.flexbox} ${style['gap-20']} ${style['flex-wrap']} ${style['content-center']}`} 
          data={ad_camp} 
          position={'after_tutorial_description_2'} 
        />
      ) : (
        ""
      )}
    </>
  );

}

const StyledList = ({ data }) => {
  const { style, items } = data;

  return (
    <div className={`${style['list-container']}`}>
    {style === 'ordered' ? (
      <ol>
        {items.map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
        ))}
      </ol>
    ) : (
      <ul>
        {items.map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
        ))}
      </ul>
    )}
  </div>  
  );
};

const ResponsiveTable = ({ data }) => {
  const { withHeadings, content } = data;

  return (
    <div className={`${style['table-container']}`}>
    <table className={`${style.table}`}>
      <thead>
        {withHeadings && (
          <tr>
            {content[0].map((heading, index) => (
              <th key={index}>{Helper.decodeHtmlEntities(heading)}</th>
            ))}
          </tr>
        )}
      </thead>
      <tbody>
        {content.slice(withHeadings ? 1 : 0).map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td
                key={cellIndex}
                data-label={
                  withHeadings
                    ? content[0][cellIndex]
                    : `Column ${cellIndex + 1}`
                }
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: Helper.decodeHtmlEntities(cell),
                  }}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>  
  );
}; 

var TutorialsList = ({ index, data, chapter_title, built_url }) => {
  
  return ( 
     
    <div className={`${style.container} ${style['white-grey-bg']} ${style['category-container']} ${style['update-chpt']}`}>
    {chapter_title !== undefined && chapter_title !== '' ? (
      <>
        <span className={`${style['cats-number']}`}>{Helper.produceNumber(index)}</span>
        <h2 className={`${style['category-headline']}`}>{chapter_title}</h2>
      </>
    ) : (
      <span className={`${style['cats-number']}`}>{Helper.produceNumber(index)}</span>
    )}
    <div className={`${style['chapter-cont']}`}>
      <ul className={`${style['tuts-categ']}`}>
        {data.map((x, index) => (
          <li key={`${x._id}-${index}`}>
            <Link aria-label={x.post_title} href={`${built_url}${x.slug}/`}>
              {Helper.decodeHtmlEntities(x.post_title)}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>  

  );
}

function TutorialsContent({ blocks, tutorials, ad_camp, settings, adsReady }){
  // //// console.log(ad_camp);
   var header_count = 0;
   var end_section = 0; 
   return (
     <Fragment>
       {blocks?.map(( x, ind ) => {
         if (x.id !== 'header-level-1') { 
            
           switch (x.type) {
             case 'paragraph':
               return (
                 <p
                   key={x.id}
                   style={{ textAlign: x?.data?.alignment }}
                   dangerouslySetInnerHTML={{ __html: x?.data?.text }}
                 />
               );
             case 'code':
              
              return <HandleCodeBlock code_value={x?.data?.value} key={x.id}/>
              /*
               return (
                 <Highlight key={x.id} className={x?.data?.language_type}>
                   {x?.data?.value}
                 </Highlight>
               );
               */
             case 'image':
              var src = x?.data?.file?.url.replace("codedtag.com", "flatcoding.com")
               return (
                <figure key={x.id}>
                <Image
                  className={`${x?.data?.stretched ? style.full : ''}`} // half
                  alt={x?.data?.caption}
                  height={250}
                  src={src}
                  width={x?.data?.file?.width}
                />
              </figure>              
               );
             case 'header':
               header_count += 1;

               return <Fragment key={`${x.id}-block-header`}>
                {
                  adsReady ?
                  <>
                    <AdCompaignBox settings={settings}
                      key={`${x.id}-ad-before`}
                      position={`before_section_title_${header_count}`}
                      data={ad_camp}
                    /> 
                  </>
                   : ""
                }
               {createElement(
                 `h${Math.min(Math.max(x?.data?.level, 1), 6)}`,
                 { key: `${x.id}-heading`, style: { textAlign: x?.data?.alignment } },
                 Helper.decodeHtmlEntities(x?.data?.text)
               )}
                {
                  adsReady ?
                  <AdCompaignBox settings={settings}
                    key={`${x.id}-ad-after`}
                    position={`after_section_title_${header_count}`}
                    data={ad_camp}
                  />: ""
                }
             </Fragment>;
             case 'youtubeEmbed':
               return <LazyLoadYouTube key={x.id} url={x.data?.url} />;
             case 'delimiter':
               return <hr key={x.id} />;
             case 'raw':
              return <HandleCodeBlock code_value={x?.data?.html} key={x.id}/>
               /*return (
                 <Highlight key={x.id} className={'html'}>
                   {x?.data?.html}
                 </Highlight>
               );*/
             case 'table':
               return <ResponsiveTable key={x.id} data={x.data} />;
             case 'list':
               return <StyledList key={x.id} data={x.data} />;
             case 'tutorialsList':
               if (x.data.selectedValue === '') {
                 return null;
               }
               const filtered = tutorials.filter(
                 tut => tut.selected_category.id === x.data.selectedValue
               );
               if (filtered.length) {
                 end_section += 1;
                 return (
                  <Fragment key={`frage-box-${x.id}`}>
                  <div className={`${style.row} ${style['mlr--15']}`} key={x.id}>
                    {filtered.map(item => (
                      <div
                        key={item._id}
                        className={`${style['sm-6']} ${style['md-4']} ${style['lg-4']} ${style['text-center']} ${style['p-all-15']}`}
                      >
                        <div className={`${style['tutorial-box']}`}>
                          {item.tutorial_svg_icon !== '' && (
                            <i
                              className={`${style['tutorial-thumbs']}`}
                              style={{ background: '#2d4756' }}
                              dangerouslySetInnerHTML={{
                                __html: item.tutorial_svg_icon,
                              }}
                            />
                          )}
                          <h3>
                            <span>{item.tutorial_title}</span>
                            {item.duration !== '' && (
                              <span className={`${style.subtitle}`}>
                                Duration:- {item.duration}
                              </span>
                            )}
                          </h3>
                          <Link
                            aria-label={item.tutorial_title}
                            className={`${style['floating-all']}`}
                            href={`/tutorials/${item.slug}/`}
                          ></Link>
                        </div>
                      </div>
                    ))}
                  </div>
                
                  {adsReady ? (
                    <AdCompaignBox
                      settings={settings}
                      key={`${x.id}-ad-end-of-section`}
                      position={`end_of_category_section_${end_section}`}
                      data={ad_camp}
                    />
                  ) : (
                    ""
                  )}
                </Fragment>
                
                 );
               }
               return null;
             default:
               return null;
           }


           

         }
         return null;
       })}
     </Fragment>
   );
}


var LazyLoadYouTube = ({ url, width = '560', height = '315', cls='' }) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const iframeRef = useRef();

  useEffect(() => {
      const observer = new IntersectionObserver(
          (entries) => {
              if (entries[0].isIntersecting) {
                  setIsIntersecting(true);
                  observer.disconnect();
              }
          },
          {
              rootMargin: '0px 0px 200px 0px' // Adjust this value as needed
          }
      );

      if (iframeRef.current) {
          observer.observe(iframeRef.current);
      }

      return () => {
          if (iframeRef.current) {
              observer.unobserve(iframeRef.current);
          }
      };
  }, []);

  return (
      <div ref={iframeRef} style={{ minHeight: height, minWidth: width }}>
          {isIntersecting ? (
              <iframe
                className={`${style[cls]}`}
                width={width}
                height={height}
                src={url}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            
          ) : (
              <div style={{ minHeight: height, minWidth: width, backgroundColor: '#000' }}></div>
          )}
      </div>
  );
}

var GenerateTutorialContent_1 = ({ data, upcoming, built_url, ad_camp, adsReady }) => {
  // Split the data by the delimiter "|"
  const parts = data.split('|').map(part => part.trim());

  // Process the parts to create the appropriate elements
  return (
    <>
      {parts.map((part, index) => {
        // YouTube shortcode
        if (part.startsWith('[youtube')) {
          const src = part.match(/src="([^"]+)"/)[1];
          return (
            <Fragment key={index}>
              <div className={`${style['mt-25']}`}>
                <LazyLoadYouTube cls={`${style['ifram-tut-youtube']}`} url={src} />
              </div>

              {adsReady ? (
                <AdCompaignBox 
                  settings={upcoming.settings} 
                  data={ad_camp} 
                  position={'after_youtube_video_content_1'} 
                />
              ) : (
                ""
              )}
            </Fragment>

          );
        }
        // Headline shortcodes from h1 to h6
        else if (part.match(/^\[h[1-6]/)) {
          const tag = part.match(/^\[h([1-6])/)[1];
          const content = part.replace(/^\[h[1-6]\]/, '').trim();
          const TagName = `h${tag}`;
          return <TagName key={index} className={`${style['tutorial-subheadline']}`}>{Helper.decodeHtmlEntities(content)}</TagName>;
        }
        // Chapters and posts shortcode
        else if (part.startsWith('[chapters-posts]')) {
          if(upcoming != undefined )
            return <TutorialLinks adsReady={adsReady} key={index} ad_camp={ad_camp} built_url={built_url} upcoming={upcoming} />;
        } 
        // Default case: plain paragraph
        else {
          return (
            <p key={index} className={`${style['tutorial-description']}`}>
              {part}
            </p>
          );
        }
      })}

      {adsReady ? <AdCompaignBox settings={upcoming.settings} data={ad_camp} position={'after_tutorial_description_1'}/>: "" }
    </>
  );
}


var CustomShareIcon = ({ IconComponent, size, width, height }) => (
  <div style={{ borderRadius: '8px', overflow: 'hidden', width: width, height: height }}>
    <IconComponent size={size} />
  </div>
);

var SocialShare = ({platforms, url, title, radius, size, width, height}) => {
  if( size == undefined ) {
    size = 32;
  }

  if( width == undefined ) {
    width = '32px';
  }
  if( height == undefined ) {
    height = '32px';
  }
  return platforms.split(',').map((platform, index) => {
    const trimmedPlatform = platform.trim().toLowerCase();
    switch (trimmedPlatform) {
      case 'email':
        return (
          <EmailShareButton 
            aria-label={title} 
            key={index} 
            url={url} 
            subject={title} 
            className={`${style['social-share-button']}`}
          >
            {radius ? (
              <CustomShareIcon 
                width={width} 
                height={height} 
                size={size} 
                IconComponent={EmailIcon} 
              />
            ) : (
              <EmailIcon 
                width={width} 
                height={height} 
                size={size} 
                round 
              />
            )}
          </EmailShareButton>
        );
      case 'facebook':
        return (
          <FacebookShareButton 
            aria-label={title} 
            key={index} 
            url={url} 
            quote={title} 
            className={`${style['social-share-button']}`}
          >
            {radius ? (
              <CustomShareIcon 
                width={width} 
                height={height} 
                size={size} 
                IconComponent={FacebookIcon} 
              />
            ) : (
              <FacebookIcon 
                width={width} 
                height={height} 
                size={size} 
                round 
              />
            )}
          </FacebookShareButton>

        );
        case 'gab':
          return (
            <GabShareButton 
              aria-label={title} 
              key={index} 
              url={url} 
              title={title} 
              className={`${style['social-share-button']}`}
            >
              {radius ? (
                <CustomShareIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  IconComponent={GabIcon} 
                />
              ) : (
                <GabIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  round 
                />
              )}
            </GabShareButton>
          );
        case 'hatena':
          return (
            <HatenaShareButton 
              aria-label={title} 
              key={index} 
              url={url} 
              title={title} 
              className={`${style['social-share-button']}`}
            >
              {radius ? (
                <CustomShareIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  IconComponent={HatenaIcon} 
                />
              ) : (
                <HatenaIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  round 
                />
              )}
            </HatenaShareButton>
          );
        case 'instapaper':
          return (
            <InstapaperShareButton 
              aria-label={title} 
              key={index} 
              url={url} 
              title={title} 
              className={`${style['social-share-button']}`}
            >
              {radius ? (
                <CustomShareIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  IconComponent={InstapaperIcon} 
                />
              ) : (
                <InstapaperIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  round 
                />
              )}
            </InstapaperShareButton>
          );
        case 'line':
          return (
            <LineShareButton 
              aria-label={title} 
              key={index} 
              url={url} 
              title={title} 
              className={`${style['social-share-button']}`}
            >
              {radius ? (
                <CustomShareIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  IconComponent={LineIcon} 
                />
              ) : (
                <LineIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  round 
                />
              )}
            </LineShareButton>
          );
        case 'linkedin':
          return (
            <LinkedinShareButton 
              aria-label={title} 
              key={index} 
              url={url} 
              title={title} 
              className={`${style['social-share-button']}`}
            >
              {radius ? (
                <CustomShareIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  IconComponent={LinkedinIcon} 
                />
              ) : (
                <LinkedinIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  round 
                />
              )}
            </LinkedinShareButton>
          );
        case 'reddit':
          return (
            <RedditShareButton 
              aria-label={title} 
              key={index} 
              url={url} 
              title={title} 
              className={`${style['social-share-button']}`}
            >
              {radius ? (
                <CustomShareIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  IconComponent={RedditIcon} 
                />
              ) : (
                <RedditIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  round 
                />
              )}
            </RedditShareButton>
          );
        case 'twitter':
          return (
            <TwitterShareButton 
              aria-label={title} 
              key={index} 
              url={url} 
              title={title} 
              className={`${style['social-share-button']}`}
            >
              {radius ? (
                <CustomShareIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  IconComponent={TwitterIcon} 
                />
              ) : (
                <TwitterIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  round 
                />
              )}
            </TwitterShareButton>
          );
        case 'whatsapp':
          return (
            <WhatsappShareButton 
              aria-label={title} 
              key={index} 
              url={url} 
              title={title} 
              separator=":: " 
              className={`${style['social-share-button']}`}
            >
              {radius ? (
                <CustomShareIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  IconComponent={WhatsappIcon} 
                />
              ) : (
                <WhatsappIcon 
                  width={width} 
                  height={height} 
                  size={size} 
                  round 
                />
              )}
            </WhatsappShareButton>
          );
        default:
          return null;      
    }
  });
}


var ArticleContentSingle = ({blocks, helper, adsReady}) => {
      
  var subheadings = blocks.filter(x => x.type == 'header' && x.id != 'header-level-1' ).map(x => ({
    href: Helper.generate_slugs(x?.data?.text),
    title: x?.data?.text
  }));

  var settings = null, ads = null; 
  if( helper != undefined ) {
    settings = helper.settings;
    ads = helper.ads;
  }
  
  if( ads == null ) ads = [];

  var words_every = settings?.ads_between_texts_every_words? settings.ads_between_texts_every_words: 500;

  var text_counter = 0;
  var ad_counter = 0;
  return(
    <>
      {blocks?.map((x, index) => {

        
        if(x.id != 'header-level-1') {
           
          // return <TableOfContent/>

          if( x.type == 'paragraph') { 
            
            text_counter += x.words_counts
            

            var ad_campaign_element = '';
            if( text_counter >= words_every ) {
              ad_counter++; 
              var elm_ent = adsReady ? <AdCompaignBox settings={settings} data={ads} position={`inside_content_${ad_counter}`}/>: ''
              ad_campaign_element = elm_ent ;
              text_counter = 0;
            }
            
            return (
              <Fragment key={x.id}> 
                <p style={{textAlign:x?.data?.alignment}} dangerouslySetInnerHTML={{__html: x?.data?.text}}/>
                { ad_campaign_element }
                {
                  index == 1 && subheadings.length ? <TableOfContent data={subheadings}/>: ""
                }
                
              </Fragment>
            )
          } else if (x.type == 'code' ) {
            return <HandleCodeBlock code_value={x?.data?.value} key={x.id}/>
            /*return (
              <Highlight key={x.id} className={x?.data?.language_type}>
                {x?.data?.value}
              </Highlight>
            )*/
          } else if (x.type == 'image') {
            var src = x?.data?.file?.url.replace("codedtag.com", "flatcoding.com")
              
            return (
              <figure key={x.id}> 
                    <Image
                        className={x?.data?.stretched ? style.full: ''}//half
                        alt={x?.data?.caption}
                        height={250}
                        src={src} // use normal <img> attributes as props
                        width={x?.data?.file?.width} /> 
              </figure>
            )
          } else if (x.type == 'header') { 

            return ( 
              createElement(`h${Math.min(Math.max(x?.data?.level, 1), 6)}`, {key: x.id, id:Helper.generate_slugs(`section-${x?.data?.text}`), style:{textAlign: x?.data?.alignment }}, Helper.decodeHtmlEntities(x?.data?.text))
            )

          } else if (x.type == 'youtubeEmbed') {
            return (<LazyLoadYouTube key={x.id} url={x.data?.url} height='500'/>);
          } else if (x.type == 'delimiter') {
            return (<hr key={x.id} />)
          } else if (x.type == 'raw') {
            return <HandleCodeBlock code_value={x?.data?.html} key={x.id}/>
            /*return (
              <Highlight key={x.id} className={'html'}>
                {x?.data?.html}
              </Highlight>
            )*/
          } else if (x.type == 'table') {
            return <ResponsiveTable key={x.id} data={x.data} />
          } else if (x.type == 'list') {
            return <StyledList key={x.id} data={x.data} />
          } else if (x.type == 'customImage') {
            var src = x?.data?.url.replace("codedtag.com", "flatcoding.com")
            
            return (
              <figure key={x.id}> 
                    <Image
                        className={x?.data?.stretched ? style.full: ''}//half
                        alt={x?.data?.alt}
                        height={320}
                        src={src} // use normal <img> attributes as props
                        width={400} /> 
              </figure>
            )
          }

        } 

      })}
    </>
  );     
}

var NextPrevPagination = ({site_url, tutorial_slug, type, data, current_post_slug, is_tab, tab_slug}) => {
     
  var isTab = is_tab == undefined ? false: is_tab;
  
  var posts = data; 
  if(type == 'chapters') {
    posts = data.map(x => x.posts).flat();
  }
   
  // get current index;
  var index = posts.findIndex( x => x.slug == current_post_slug );

  
  var nex_index = index + 1;
  var prev_index = index - 1;


  var next = posts[nex_index];
  var prev = posts[prev_index]
  
  var next_link = next == undefined ? '':`${site_url}tutorials/${tutorial_slug}/${next.slug}/`;
  var prev_link = prev == undefined ? '':`${site_url}tutorials/${tutorial_slug}/${prev.slug}/`;
 
  if( isTab ) {
    var tab_slug_update =  tab_slug ? tab_slug: 'reference';

    next_link = next == undefined ? '':`${site_url}tutorials/${tutorial_slug}/t/${tab_slug_update}/${next.slug}/`;
    prev_link = prev == undefined ? '':`${site_url}tutorials/${tutorial_slug}/t/${tab_slug_update}/${prev.slug}/`;
  }

  return (
    <div className={`${style.flexbox} ${style['space-between']} ${style.pagination}`}>
    {prev !== undefined && (
      <Link 
        aria-label={Helper.decodeHtmlEntities(prev.post_title)} 
        href={prev_link} 
        className={`${style.flexbox} ${style['direction-row']} ${style['items-center']} ${style['hover-to-left']}`}
      >
        <i className={`${style['left-arrow-pagin']}`}></i>
        <span>
          <span className={`${style['d-none']} ${style['d-sm-block']}`}>
            {Helper.decodeHtmlEntities(prev.post_title)}
          </span>
          <span className={`${style['d-block']} ${style['d-sm-none']}`}>Prev</span>
        </span>
      </Link>
    )}
  
    {next !== undefined && (
      <Link 
        aria-label={Helper.decodeHtmlEntities(next.post_title)} 
        href={next_link} 
        className={`${style.flexbox} ${style['direction-row']} ${style['items-center']} ${style['hover-to-right']} ${style['auto-right']}`}
      >
        <span>
          <span className={`${style['d-none']} ${style['d-sm-block']}`}>
            {Helper.decodeHtmlEntities(next.post_title)}
          </span>
          <span className={`${style['d-block']} ${style['d-sm-none']}`}>Next</span>
        </span>
        <i className={`${style['right-arrow-pagin']}`}></i>
      </Link>
    )}
  </div>  
  );
}



var Breadcrumbs = ({data}) => {
  return (
    <ul className={`${style.breadcrumbs}`}>
      {data.map((x, index) => (
        <li key={index} className={`${style['sub-title']}`}>
          <Link 
            aria-label={Helper.decodeHtmlEntities(x.title)} 
            href={x.url}
          >
            {Helper.decodeHtmlEntities(x.title)}
          </Link>
        </li>
      ))}
    </ul>
  );
}

var ArticleSidebar = ({type, data, site_url, tutorial_slug, current_post_slug, tab_slug, helper, adsReady}) => {

  var settings = null, ads = []; 
  if( helper != undefined ) {
    ads = helper.ads
    settings = helper.settings
  } 

  var collapsed_item = (e, id) => {
    
    e.preventDefault();

    var doc_id = document.querySelector(`#item-${id}`); 
    var anchor = document.querySelector(`#anchor-${id}`); 
    

    if( doc_id.classList.contains(style.expanded) ) {
      anchor.classList.remove(style['expanded-a'])
      doc_id.classList.remove(style['expanded']); 
    } else {
      doc_id.classList.add(style['expanded']);
      anchor.classList.add(style['expanded-a'])
    }
      
  }

  var posts = [];
  if( type == 'posts' ) {
    if(data.length)
      posts = Helper.chunkArray(data, settings.ads_between_navs_every_list ) 
  }

  var chapters = []; 
  if( type == 'chapters' ) {
    if(data.length)
      chapters = Helper.chunkArray(data, settings.ads_between_navs_every_list ) 
  } 

  var elem_list = 0;


  
  var itemComponents = (
    <>

      {/* Chapters */}
      {type === 'chapters' ? 
        // split chapters according to ads 
         

        chapters.map( (chapterData, indexer) => {
          
          elem_list++;

          return (
            <Fragment key={indexer}>
              <ul key={indexer} className={`${style['block-list']} ${style['custom-aside-tuts']}`}>
                {chapterData.map(chapter => {
                  let link_url = `${site_url}tutorials/${tutorial_slug}/`;
                  if (tab_slug !== undefined) {
                    link_url = `${link_url}t/${tab_slug}/`;
                  }

                  const is_expanded = chapter.posts.findIndex(x => x.slug === current_post_slug) !== -1;

                  return (
                    <Fragment key={chapter._id}>
                      {chapter.chapter_title !== "" ? (
                        <>
                          <li className={`${chapter.posts.length ? style['has-slideitem'] : ''}`}>
                            <Link
                              aria-label={chapter.chapter_title}
                              className={`${is_expanded ? style['expanded-a'] : ''}`}
                              id={`anchor-${chapter._id}`}
                              onClick={e => collapsed_item(e, `${chapter._id}`)}
                              href="#"
                            >
                              {chapter.chapter_title}
                            </Link>
                            {chapter.posts.length ? (
                              <ul
                                id={`item-${chapter._id}`}
                                className={`${style.collapsible} ${style['list-items']} ${is_expanded ? style.expanded : ''}`}
                              >
                                {chapter.posts.map(x => (
                                  <li key={x._id}>
                                    <Link
                                      aria-label={Helper.decodeHtmlEntities(x.post_title)}
                                      className={`${current_post_slug === x.slug ? style['selected_tab'] : ''}`}
                                      href={`${link_url}${x.slug}/`}
                                    >
                                      {Helper.decodeHtmlEntities(x.post_title)}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            ) : null}
                          </li>
                        </>
                      ) : (
                        <li>
                          <ul className={`${style['block-list']} ${style['custom-aside-tuts']} ${style['list-items']}`}>
                            {chapter.posts.map((x, index) => (
                              <li key={`${x._id}-${index}`}>
                                <Link
                                  aria-label={Helper.decodeHtmlEntities(x.post_title)}
                                  className={`${current_post_slug === x.slug ? style['selected_tab'] : ''}`}
                                  href={`${link_url}${x.slug}/`}
                                >
                                  {Helper.decodeHtmlEntities(x.post_title)}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      )}
                    </Fragment>
                  );
                })}
              </ul>

              {adsReady ? (
                <AdCompaignBox
                  settings={settings}
                  data={ads}
                  position={`in_sidebar_${elem_list}`}
                />
              ) : (
                ""
              )}
            </Fragment>

          )

        })
       
      : ''}
      
      {/* Posts */} 
      {
        (type == 'posts') ?
        posts.map( (x, index) =>{
          
          if(  x.length >= settings.ads_between_navs_every_list )
            elem_list++;

          return  (
            <Fragment key={x._id}>
              <ul
                key={`post-${x._id}-${index}`}
                className={`${style['block-list']} ${style['custom-aside-tuts']} ${style['list-items']}`}
              >
                {x.map(post => {
                  let link_url = `${site_url}tutorials/${tutorial_slug}/`;
                  if (tab_slug !== undefined) {
                    link_url = `${link_url}t/${tab_slug}/`;
                  }

                  return (
                    <Fragment key={post._id}>
                      <li key={post._id}>
                        <Link
                          aria-label={Helper.decodeHtmlEntities(post.post_title)}
                          className={`${current_post_slug === post.slug ? style['selected_tab'] : ''}`}
                          href={`${link_url}${post.slug}/`}
                        >
                          {Helper.decodeHtmlEntities(post.post_title)}
                        </Link>
                      </li>
                    </Fragment>
                  );
                })}
              </ul>

              {x.length >= settings.ads_between_navs_every_list ? (
                adsReady ? (
                  <AdCompaignBox
                    settings={settings}
                    data={ads}
                    position={`in_sidebar_${elem_list}`}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Fragment>

          )
        }): ''
      }
    </>
  );
  

  
  return (itemComponents);
}

const TableOfContent = ({ data }) => {
  const [expandorCheckbox, setExpandorCheckbox] = useState(false);

  useEffect(() => {
    const expandCollapseTblContent = () => {
      const id = document.querySelector('#article-tbl-of-content');
      const handler = document.querySelector('#table-of-content-toggler');

      if (id?.classList.contains(style.expanded)) {
        id.classList.remove(style.expanded);
        handler.classList.remove(style['tbl-arrow']);
      } else {
        id?.classList.add(style.expanded);
        handler?.classList.add(style['tbl-arrow']);
      }
    };

    const handler = document.querySelector('#table-of-content-toggler');
    if (handler) {
      handler.addEventListener('click', expandCollapseTblContent);
    }

    return () => {
      if (handler) {
        handler.removeEventListener('click', expandCollapseTblContent);
      }
    };
  }, []);

  const handleSmoothScroll = (e, href) => {
    e.preventDefault();
    
    const targetElement = document.querySelector( '#section-'+ href);
      
    if (targetElement) { 
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
        id="article-tbl-of-content"
        className={`${style['content-tble-mobile-block']} ${style['tble-content']} ${
          expandorCheckbox ? style.expanded : ''
        }`}
      >
        <ul className={`${style['block-list']} ${style['custom-aside-tuts']} ${style['list-items']}`}>
          <li className={`${style['has-slideitem']}`} style={{ background: '#f9f9f9' }}>
            <b className={`${style['content-table-head-title']}`}>Table of Content</b>
            <ul className={`${style.slideitem}`} style={{ display: 'block' }}>
              {data.map((x, index) => (
                <li key={index}>
                  <a
                    href={x.href ? `#section-${Helper.decodeHtmlEntities(x.href)}` : '#'}
                    onClick={(e) => handleSmoothScroll(e, x.href)}
                  >
                    {Helper.decodeHtmlEntities(x.title)}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        </ul>
        <label
          className={`${style['tble-content-handler']} ${style.expander}`}
          id="table-of-content-toggler"
        ></label>
      </div>

  );
};

/*
const FaqsSection = ({ faqs_section }) => {
  // Initialize all answers as collapsed (false)
  const [isExpanded, setIsExpanded] = useState(faqs_section.map(() => false));

  // Function to toggle the expanded state
  const toggleExpansion = (currentIndex) => {
    setIsExpanded((prevState) =>
      prevState.map((item, index) => (index === currentIndex ? !item : false))
    );
  };

  return (
    <div className="faqs-section">
      <h3>Frequently Asked Questions (FAQs)</h3>
      <ul>
 
        {faqs_section.map((faq, index) => {
          // Process the answer for highlighting and inline code rendering
          const answerParts = faq.answer.split(/\{\`\*class=['"]([^'"]+)['"]\*\s([^`]*)\`\}/g);
           
          let processedAnswer = answerParts.map((part, idx) => {
            if (idx % 3 === 0) {
 
              return part.split('|').map((segment, i) => {
                const inlineProcessed = segment.split(/`([^`]*)`/g).map((inlinePart, j) => {
                  return j % 2 === 0 ? (
                    <span key={`${idx}-${i}-${j}`}>{inlinePart}</span>
                  ) : (
                    <code key={`${idx}-${i}-${j}`} className="inline-code">{inlinePart}</code>
                  );
                });
                return <p key={`${idx}-${i}`}>{inlineProcessed}</p>;
              });
            } else if (idx % 3 === 1) {
              const className = part;
              const codeValue = answerParts[idx + 1];
              return (
                <Highlight key={idx} className={className}>
                  {codeValue}
                </Highlight>
              );
            } else {
              return null;
            }
          });

          return ( 
            <li key={index}>
              <h4 style={{ borderBottomWidth: isExpanded[index] ? '1px' : '0' }} onClick={() => toggleExpansion(index)} className="faq-question">
                <span>
                {faq.question}
                </span>
                <span className={`faq-arrow ` + (isExpanded[index] ? 'expanded': '')}></span>
              </h4>
               <div
                className="faq-answer"
                style={{
                  maxHeight: isExpanded[index] ? '500px' : '0',
                  opacity: isExpanded[index] ? 1 : 0,
                  overflow: 'hidden',
                  transition: 'max-height 0.1s ease, opacity 0.3s ease',
                  padding: isExpanded[index] ? '20px' : '0',
                }}
              >
                {processedAnswer}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
*/

 
const FaqsSection = ({ faqs_section }) => {
  const [isExpanded, setIsExpanded] = useState(faqs_section.map(() => false));
  const [hasPadding, setHasPadding] = useState(faqs_section.map(() => false));

  const toggleExpansion = (currentIndex) => {
    setIsExpanded((prevState) =>
      prevState.map((item, index) => (index === currentIndex ? !item : false))
    );

    // Manage padding state after the transition
    setTimeout(() => {
      setHasPadding((prevState) =>
        prevState.map((item, index) =>
          index === currentIndex ? !item : false
        )
      );
    }, 300); // Match the transition duration
  };

  var hasHtmlTags = (str) => {
      const htmlTagRegex = /<\/?[a-z][\s\S]*>/i;
      return htmlTagRegex.test(str);
  }


  return (
    <div className={`${style['faqs-section']}`}>
      <h3>Frequently Asked Questions (FAQs)</h3>
      <ul>
        {faqs_section.map((faq, index) => {
          const answerParts = faq.answer.split(
            /\{\`\*class=['"]([^'"]+)['"]\*\s([^`]*)\`\}/g
          );
  
          const processedAnswer = answerParts.map((part, idx) => {
            if (idx % 3 === 0) {
              return part.split(/(?<!\|)\|(?!\|)/g).map((segment, i) => {
                const inlineProcessed = segment
                  .split(/`([^`]*)`/g)
                  .map((inlinePart, j) => {
                    return j % 2 === 0 ? (
                      hasHtmlTags(inlinePart) ? (
                        <div
                          key={`${idx}-${i}-${j}`}
                          dangerouslySetInnerHTML={{ __html: inlinePart }}
                        />
                      ) : (
                        <span key={`${idx}-${i}-${j}`}>{inlinePart}</span>
                      )
                    ) : (
                      <code
                        key={`${idx}-${i}-${j}`}
                        className={`${style['inline-code']}`}
                      >
                        {inlinePart}
                      </code>
                    );
                  });
  
                return <div key={`${idx}-${i}`}>{inlineProcessed}</div>;
              });
            } else if (idx % 3 === 1) {
              const className = part;
              const codeValue = answerParts[idx + 1];
  
              return <FaqHandleCodeBlock code_value={codeValue} key={index} />;
            } else {
              return null;
            }
          });
  
          const AnswerBlock = ({ answer = "" }) => {
            if (!answer) return null;
  
            const wrappedAnswer = answer
              .replace(
                /(<pre>[\s\S]*?<\/pre>)|<code[\s\S]*?>([\s\S]*?)<\/code>|`(.*?)`/g,
                (match, preBlock, codeContent, rawCode) => {
                  if (preBlock) {
                    return preBlock;
                  }
                  if (rawCode) {
                    return `<code class="${style['inline-code']}">${Helper.encodetmlEntities(
                      rawCode
                    )}</code>`;
                  }
                  return match;
                }
              )
              .replace(
                /(<code[\s\S]*?>[\s\S]*?<\/code>)|`(.*?)`/g,
                (match, codeBlock, backtickContent) => {
                  if (codeBlock) return codeBlock;
                  if (backtickContent) {
                    return `<code class='${style['inline-code']}'>${Helper.encodetmlEntities(
                      backtickContent
                    )}</code>`;
                  }
                  return match;
                }
              )
              .replace(
                /(<code[\s\S]*?>[\s\S]*?<\/code>)|(\|)/g,
                (match, codeBlock, pipeline) => {
                  if (codeBlock) return codeBlock;
                  if (pipeline) return "";
                  return match;
                }
              );
  
            const segments = wrappedAnswer.split(
              /(<pre><code>[\s\S]*?<\/code><\/pre>)/g
            );
  
            return (
              <div>
                {segments.map((input, index) => {
                  const match = input.match(/<code>([\s\S]*?)<\/code>/);
                  if (match) {
                    return <Highlight key={index}>{match[1]}</Highlight>;
                  }
  
                  return (
                    <div key={index} dangerouslySetInnerHTML={{ __html: input }} />
                  );
                })}
              </div>
            );
          };
  
          return (
            <li key={index}>
              <h4
                style={{
                  borderBottomWidth: isExpanded[index] ? "1px" : "0",
                }}
                onClick={() => toggleExpansion(index)}
                className={`${style['faq-question']}`}
              >
                <span>{faq.question}</span>
                <span
                  className={`${style['faq-arrow']} ${
                    isExpanded[index] ? style.expanded : ""
                  }`}
                ></span>
              </h4>
              <div
                className={`${style['faq-answer']}`}
                ref={(el) => {
                  if (el && isExpanded[index]) {
                    el.style.maxHeight = `${el.scrollHeight}px`;
                  } else if (el) {
                    el.style.maxHeight = "0";
                  }
                }}
                style={{
                  overflow: "hidden",
                  transition: "max-height 0.3s ease, opacity 0.3s ease",
                  opacity: isExpanded[index] ? 1 : 0,
                }}
              >
                <div style={{ padding: "20px" }}>
                  <AnswerBlock answer={faq.answer} />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
  
};


const BlogFaqsSection = ({ faqs_section }) => {
  const [isExpanded, setIsExpanded] = useState(faqs_section.map(() => false));

  const toggleExpansion = (currentIndex) => {
    setIsExpanded((prevState) =>
      prevState.map((item, index) => (index === currentIndex ? !item : false))
    );
  };

  return (
    <div className={`${style['faqs-section']}`}>
      <h3>Frequently Asked Questions (FAQs)</h3>
      <ul>
        {faqs_section.map((faq, index) => (
          <li key={index}>
            <h4
              onClick={() => toggleExpansion(index)}
              className={`${style['faq-question']}`}
              style={{
                borderBottomWidth: isExpanded[index] ? "1px" : "0",
              }}
            >
              <span>{faq.question}</span>
              <span
                className={`${style['faq-arrow']} ${
                  isExpanded[index] ? style.expanded : ""
                }`}
              ></span>
            </h4>
            <div
              className={`${style['faq-answer']}`}
              ref={(el) => {
                if (el && isExpanded[index]) {
                  el.style.maxHeight = `${el.scrollHeight}px`;
                } else if (el) {
                  el.style.maxHeight = "0";
                }
              }}
              style={{
                overflow: "hidden",
                transition: "max-height 0.3s ease, opacity 0.3s ease",
                opacity: isExpanded[index] ? 1 : 0,
              }}
            >
              <div className={style.faq_section_list} style={{ padding: "20px" }} dangerouslySetInnerHTML={{ __html: Helper.decodeHtmlEntities(faq.answer) }}/>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};


var ArticleContent = ({blocks}) => {
      
  var subheadings = blocks.filter(x => x.type == 'header' && x.id != 'header-level-1' ).map(x => ({
    href: Helper.generate_slugs(x?.data?.text),
    title: x?.data?.text
  }));

  return(
    <>
      {blocks?.map((x, index) => {

       

        if(x.id != 'header-level-1') {
           
          // return <TableOfContent/>

          if( x.type == 'paragraph') {
            return (
              <Fragment key={x.id}>
                <p style={{textAlign:x?.data?.alignment}} dangerouslySetInnerHTML={{__html: x?.data?.text}}/>
                {
                  index == 1 && subheadings.length ? <TableOfContent data={subheadings}/>: ""
                }
              </Fragment>
            )
          } else if (x.type == 'code' ) {
            return <HandleCodeBlock code_value={x?.data?.value} key={x.id}/>
            /*
            return (
              <Highlight key={x.id} className={x?.data?.language_type}>
                {x?.data?.value}
              </Highlight>
            )*/
          } else if (x.type == 'image') {
            var src = x?.data?.file?.url.replace("codedtag.com", "flatcoding.com")
            
            return (
              <figure key={x.id}> 
                    <Image
                        className={x?.data?.stretched ? style.full: ''} //half
                        alt={x?.data?.caption}
                        height={250}
                        src={src} // use normal <img> attributes as props
                        width={x?.data?.file?.width} /> 
              </figure>
            )
          } else if (x.type == 'header') { 

            return ( 
              createElement(`h${Math.min(Math.max(x?.data?.level, 1), 6)}`, {key: x.id, id:Helper.generate_slugs(`section-${x?.data?.text}`), style:{textAlign: x?.data?.alignment }}, Helper.decodeHtmlEntities(x?.data?.text))
            )

          } else if (x.type == 'youtubeEmbed') {
            return (<LazyLoadYouTube key={x.id} url={x.data?.url} height='500'/>);
          } else if (x.type == 'delimiter') {
            return (<hr key={x.id} />)
          } else if (x.type == 'raw') {
            return <HandleCodeBlock code_value={x?.data?.html} key={x.id}/>
            /*
            return (
              <Highlight key={x.id} className={'html'}>
                {x?.data?.html}
              </Highlight>
            )*/
          } else if (x.type == 'table') {
            return <ResponsiveTable key={x.id} data={x.data} />
          } else if (x.type == 'list') {
            return <StyledList key={x.id} data={x.data} />
          } else if (x.type == 'customImage') {
             
            var src = x?.data?.url.replace("codedtag.com", "flatcoding.com")
            return (
              <figure key={x.id}> 
                    <Image
                        className={x?.data?.stretched ? style.full: ''}//half
                        alt={x?.data?.alt}
                        height={320}
                        src={src} // use normal <img> attributes as props
                        width={400} /> 
              </figure>
            )
          }

        } 

      })}
    </>
  );     
}
/*
var TableOfContent = ({data}) => {

  var [expandor_checkbox, expandor_checkbox_change] = useState(false);
  var expand_collapse_tbl_content = () => {
      var id = document.querySelector('#article-tbl-of-content');
      var handler = document.querySelector('#table-of-content-toggler');

      if(id.classList.contains('expanded')) {
          id.classList.remove('expanded')
          handler.classList.remove('tbl-arrow')
      } else {
          id.classList.add('expanded')
          handler.classList.add('tbl-arrow')
      }
  } 
  return (
    <div id='article-tbl-of-content' className={`content-tble-mobile-block tble-content ${expandor_checkbox ? 'expanded': ''}`}>
        <ul className="block-list custom-aside-tuts list-items">
            <li className="has-slideitem" style={{background: "#f9f9f9"}}>
                <b className='content-table-head-title'>Table of Content</b>
                <ul className="slideitem" style={{display: "block"}}>
                  {data.map((x, index) => <li key={index}><Link href={x.href == undefined ? '#': x.href} smooth={'true'} duration={500}>{x.title}</Link></li>)} 
                </ul>
            </li>
        </ul>
        <label className={"tble-content-handler expander"} id='table-of-content-toggler' onClick={expand_collapse_tbl_content}></label>
    </div>
  )
}*/
export {
  SearchComponent, 
  SubscribeComponents,
  TutorialsContent,
  ServerOffline,
  GenerateTutorialContent_1,
  GenerateTutorialContent_2,
  SocialShare,
  FeedBackBlock,
  ArticleSidebar,
  Breadcrumbs,
  NextPrevPagination,
  ArticleContentSingle,
  ArticleContent,
  GenerateTutorialContent_tab,
  FaqsSection,
  BlogFaqsSection,
  CreateCaptcha
}